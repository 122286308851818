.btn {
  margin-right: 10px;
  margin-bottom: 5px;
}
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}

.navbar {
  background-color: #2e326f !important;
}

.btn-primary,
.btn {
  color: #fff;
  background-color: #2e326f !important;
  border-color: #2e326f !important;
}

.spacer {
  height: 40px;
}
